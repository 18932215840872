import React, {  } from "react";
import { useState } from "react";
import {  Row,Col } from "antd";
import { withTranslation } from "react-i18next";
//import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
//import { Carousel } from 'antd';
import { Slide } from "react-awesome-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
//import { Icon } from '@iconify/react';

import { Button } from "../../common/Button";
import {
  CarouselSection,
} from "./styles";


import {
  RightBlockContainer,
  Content,
  ContentWrapper,
  ButtonWrapper,
} from "../ContentBlock/RightContentBlock/styles";

/* const scrollTo3 = (id: string) => {
	
    // let element = document.getElementById(id) as HTMLDivElement;
     console.log("AAA"+id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };*/
  /* {
      "title": "",
      "titlescroll":"product",
      "hassubtitle": false,
       "subtitle": [],
      "imgname": "saas.jpg",
      "bgclass": "cat-bg-clr-2",
      "btnurl": "saas"
    }*/
const Carouseldiv = ({ section, t }: any) => {
	const [visible, setVisibility] = useState(false);
	const [selected, setSelected] = useState("intro");

 const scrollTo3 = (id: string) => {
 console.log("AAA"+id);
      setSelected(id);      
      const element = document.getElementById(id) as HTMLDivElement;

      if(element == null) {
      	
      	/*if(id.indexOf("/"))
      		document.location.href =""+id;
      	else */
	      	document.location.href ="/#"+id;
      	return false;
      }
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
      
      var _ele;      
      _ele = document.getElementById("spnintro") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnservice") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnproduct") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnrequestdemo") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnabout") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spncontact") as HTMLDivElement;
      _ele.classList.remove("activeClass"); 
      
      _ele = document.getElementById("spn"+id) as HTMLDivElement;
      _ele.classList.add("activeClass"); 
      
      
    };
   const settings = {
      dots:false,
      infinite:true,

      speed:3000,
      slidesToShow:1,
      autoplay:true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      lazyLoad: true,

       	fade: true,

      slidesToScroll: 1,adaptiveHeight:false,
      responsive: [
      {
        breakpoint: 20,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 80,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      ]
    };
    const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <CarouselSection>

    <div className="carousel">
     <Row justify="space-between" align="middle">
		 <Col lg={14} md={10} sm={24} xs={24}  className="sliderl">   

              <h6 className="h6">Our Products</h6>
              <div className="homebanner">
              <div className="row">
              <div className="banner-text">

                    <Row justify="space-between" align="middle">
                         <Col lg={8} md={12} sm={24} xs={24} > 
                        	    <a href="/ecargo" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Logistics-Management-Software.webp" alt="Logistics Management Software" />
                                </div>
                                <div className="web-text">
                                    <h4>Logistics Management Software</h4>
                                </div>
                            </a>
                            <a href="/pickup-delivery-management-system" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/rms.webp" alt="Car Rental Management System" />
                                </div>
                                <div className="web-text">
                                    <h4>Car Rental Management System</h4>
                                </div>
                            </a>
                            <a href="/asset-management-software" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Assets-Management.webp" alt="HRMS Management Software" />
                                </div>
                                <div className="web-text">
                                    <h4>HRMS Management Software</h4>
                                </div>
                            </a>
                             <a href="/inventory-management-software" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Inventory-Management-Software.webp" alt="Inventory Management Software" />
                                </div>
                                <div className="web-text">
                                    <h4>Inventory Management Software</h4>
                                </div>
                            </a>
                           </Col>
                        <Col lg={8} md={12} sm={24} xs={24} >  
                            <a href="/courier-management-software" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Courier-Management-Software.webp" alt="Courier Management Software" />
                                </div>
                                <div className="web-text">
                                    <h4>Courier Management Software</h4>
                                </div>
                            </a>

                           
                            <a href="/transportation-management-software" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Transportation-Management-Software.webp" alt="Transportation Management Software" />
                                </div>
                                <div className="web-text">
                                    <h4>Transportation Management Software</h4>
                                </div>
                            </a>
                           
                           
                           
                            <a href="/quotation-management-software" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Quotation-Management-Software.webp" alt="Quotation Management Software" />
                                </div>
                                <div className="web-text">
                                    <h4>Quotation Management Software</h4>
                                </div>
                            </a>
					<a href="/pickup-delivery-management-system" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Pickup-Delivery-Management-System.webp" alt="Pickup Delivery Management System" />
                                </div>
                                <div className="web-text">
                                    <h4>Pickup Delivery Management System</h4>
                                </div>
                            </a>
                        </Col>
                        <Col lg={8} md={12} sm={24} xs={24} > 
                          <a href="/international-courier-management-software" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Remit-Management.webp" alt="Money Remittance" />
                                </div>
                                <div className="web-text">
                                    <h4>Money Remittance</h4>
                                </div>
                            </a>
                         <a href="/payroll-management-software" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Payroll-Management-Software.webp" alt="Payroll Management Software" />
                                </div>
                                <div className="web-text">
                                    <h4>Payroll Management Software</h4>
                                </div>
                            </a>
                           
                       
                            <a href="/real-time-proof-of-delivery" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Real-Time-POD.webp" alt="Real Time POD" />
                                </div>
                                <div className="web-text">
                                    <h4>Real Time POD</h4>
                                </div>
                            </a>
                            
                           
                            <a href="/courier-tracking-website" className="web-points">
                                <div className="web-icon">
                                    <img src="/img/svg/assets/images/icon-fill/Courier-Tracking-Website.webp" alt="Waybill Tracking Website" />
                                </div>
                                <div className="web-text">
                                    <h4>Waybill Tracking Website</h4>
                                </div>
                            </a>
                           
                            
                            
						
                           
                        </Col>
                    </Row>
                </div>
               </div>
              
              </div>
              
             

            
		 </Col>
 		 <Col lg={10} md={12} sm={24} xs={24} > 	
            <Slider {...settings} >
			
            {typeof section === "object" &&
           section.map((item: any, id: number) => {

           
             return (
		 <Col lg={24} md={24} sm={24} xs={24}  >
		   <div className="amu1">
			<div className={`twm-media  ${item.bgclass} leftDiv`}>
				<SvgIcon src={`${item.imgname}`} width="100%" height="100%" />
			</div>
			
			 
		   </div>
		</Col>
		);
           })}

                       </Slider>   
                       </Col> 
                       <Col lg={24} md={24} sm={24} xs={24}  >
                       </Col>        
                       </Row>
          </div>
           
            
         

    </CarouselSection>
  );
};
/*lsof -i -P -n | grep "3000"  <div className="rightDiv">
 <ButtonWrapper>
               
                      <Button
                        
                        fixedWidth={true}
                        onClick={() => scrollTo(`product`)}
                      >
                        title
                      </Button>

               
              </ButtonWrapper>
			  <Slide direction="left"><div className="carouseltitle"><h2>{item.title}</h2></div></Slide>
			  <Slide direction="up">

			  <ul>
			  {typeof item.subtitle === "object" &&
		           item.subtitle.map((itemli: any, id: number) => {
		           return (<li className="carouseldesc ">{itemli.subtitle}</li>
		           );
		           })}
		           </ul>
		           <div className="twm-read-more"><a className="site-button" href={item.titlescroll}>Read More</a></div>
		           </Slide>
		     </div>
		     */
export default withTranslation()(Carouseldiv);
