import React, { Component }  from 'react';
import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

export const Button = ({
  color,border,bcolor,
  fixedWidth,
  children,
  onClick,
}: ButtonProps) => (
<StyledButton border={border} color={color}  bcolor={bcolor}  fixedWidth={fixedWidth} onClick={onClick}>
    {children}
</StyledButton>
);
