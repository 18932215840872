const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/privacy"],
    exact: true,
    component: "Privacy",
  },
  {
    path: ["/rfq"],
    exact: true,
    component: "Rfq",
  },
  {
    path: ["/terms"],
    exact: true,
    component: "Terms",
  },
  {
    path: ["/products"],
    exact: true,
    component: "Products",
  },
  {
    path: ["/services"],
    exact: true,
    component: "Services",
  },
  {
    path: ["/ecargo"],
    exact: true,
    component: "Ecargo",
  },
  {
    path: ["/hris"],
    exact: true,
    component: "Hris",
  },
  {
    path: ["/rms"],
    exact: true,
    component: "Rms",
  },
  {
    path: ["/remit"],
    exact: true,
    component: "Remit",
  },
];

export default routes;
