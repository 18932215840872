import styled from "styled-components";

export const CarouselSection = styled("section")`
	*background:red; 
	padding:0 3rem; 
	.sliderl{padding:1rem;}
	.h6{font-size:45px;}
	
	
.homebanner{
	margin-top: 8px;
}

 .homebanner h5 {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 30px;
        text-align: center;
    }


  .homebanner h1 {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 30px;
        text-align: center;
    }

.homebanner .banner-text h4{
	font-size: 18px;
	color: #6a6c6a;
}

.homebanner .feature-heading h4{
	font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom:8px
}
.homebanner .feature-heading h5{
	font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom:10px;
    text-align:left;
}
.homebanner .banner-text .web-points{

}

.homebanner .button{
	text-align: center;
	margin-top: 30px;
}

.homebanner .web-points:hover{
	transform: translatey(-5px);
	transition: 0.3s;
}

.homebanner .banner-text .web-points{
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	box-shadow: 0 0 10px 7px rgb(0 0 0 / 3%);
	border-radius: 5px;
	padding:8px;
	transition: 0.3s;
}

.homebanner .banner-text .web-points .web-icon{
	margin-right: 10px;
	border-right: 1px solid #f2f2f2;
    padding-right: 10px;
}


.homebanner .banner-text .web-points .web-text p{
	font-size: 14px;
}

.homebanner .banner-text .web-points .web-text h4{
	font-size: 16px;
	font-weight: 500;
	color: #000;
	margin-bottom: 0px;
}

.homebanner .banner-text .web-points ul li{
	margin-bottom: 15px;
	list-style: disc;
}

.homebanner .banner-text .web-points ul{
	padding-left: 15px;
}

	
	.leftDiv  {
	   float: left;
	   width: 100%;
		@media (max-width: 400px) {
			height:32vh;
		}
   }
   

.site-button {
  outline: none;
  color: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-table;
  background-color: #1967d2;
  border: none;
  font-size: 14px;
  font-weight: 600;
  transition: 0.5s all ease;
  @media (max-width: 400px) {
	font-size: 10px;
	  padding: 7px 14px;		
  }
}
.site-button:hover {
  color: #000;
}
.site-button i {
  padding-right: 10px;
}
 .twm-read-more .site-button {
  color: rgba(0, 0, 0, 0) !important;
  transition: all 0.3s ease !important;
  text-shadow: 0 0 0 #1967d2, 400px 0 0 #1967d2;
  background-color: #fff;
}
 .twm-read-more .site-button:hover {
  text-shadow: -400px 0 0 #1967d2, 0 0 0 #1967d2;
}
.twm-read-more .site-button:hover i {
  text-shadow: none;
}
 .twm-read-more .site-button i {
  color: #1967d2;
  padding-right: 0px;
  padding-left: 5px;
  font-size: 24px;
  text-shadow: none;
}

 .twm-read-more .site-button {
  background-color: #ffb03c;
  color: #fff;
  text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
}
 .twm-read-more .site-button:hover {
  #text-shadow: -400px 0 0 #fff, 0 0 0 #fff;
  text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
  background-color: #ff825c; color:#ffffff; font-weight:bolder;
}
 .twm-read-more .site-button i {
  color: #1967d2;
  padding-right: 0px;
  padding-left: 5px;
  font-size: 24px;
}
   .center{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
   .colclass{background:gold;}
      .colclass1{background:goldenrod;}

   .carouseltitle{
   	font-size:45px; font-weight:bold; color:#074361;
   	font-family: "Motiva Sans Bold", serif;  
   	color: #f5a98f;
   margin-top: -5rem;
@media (max-width: 400px) {
		margin-top: -10rem;
		
	}
   }  
    
   .carouseltitle>h2{color:#a5eb07 !important;}

.carouseldesc{
   	font-size:20px; font-weight:bold; color:#074361;
   	font-family: "Motiva Sans light", serif;  
   	color: #cbc3d6; white-space:pre;	
   }   
   .top-title{
   	font-size:50px; font-weight:bold; color:#638300;white-space:nowrap;text-align:left;background:#ececec;
   	font-family: "Motiva Sans Bold", serif;   	
   }
   
   .justifyy{
   		text-align:justify;padding:10px;color:#d39338;
   		*font-family:"Motiva Sans bold", sans-serif !important;
   }
   	.rightDiv  {
   float: left;
   position:absolute;
   margin-top:14%;
   left:6%;
   *width: 50%;
   

   }
	@media only screen and (max-width: 768px) {
    .rightDiv { margin-top:10rem;}    
    .carouseldesc{
   	font-size:12px; 
   	}
  }	
	.slick-prev::before, .slick-next::before{
		color:#808080
	}
.slick-prev{
	position: absolute;
left: 3%;
top: 50%;
z-index: 998;display:none !important;
}

.slick-next{
	position: absolute;
right: 3%;
top: 50%;
z-index: 998;display:none !important;
}
	.ant-carousel .slick-dots li button {
		background:#698808;
	}

`;





