import { lazy, Suspense } from "react";
import { Switch, Route,useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Carouseldiv from "../components/Carousel";
import routes from "./config";
import { Styles } from "../styles/styles";
import CarouselContent from "../content/Carouselcontent.json";


const Router = () => {
  const location = useLocation();
  
//  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const abc = '<Route path="*"  element={<Navigate to="/" />} />';
  if(location.pathname == "/" || location.pathname == "/privacy" || location.pathname == "/products" || location.pathname == "/terms" || location.pathname == "/rfq" || location.pathname == "/services" || location.pathname == "/ecargo"){}
  else if(location.pathname == "/hris" || location.pathname == "/remit" || location.pathname == "/rms" ){}
  else {
  	window.location.replace('https://www.6iinfotech.com/');
  }
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      {location.pathname == "/" &&  <Carouseldiv section={CarouselContent.section} /> }
      <Switch>
      
        {routes.map((routeItem) => {
          return (            
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
        return (<Route path="*"  component={lazy(() => import(`../pages/Home`))} />);
        
      </Switch>
      
      <Footer />
    </Suspense>
  );
};

export default Router;
