import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";


import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";


const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
    const [selected, setSelected] = useState("intro");
     

  const showDrawer = () => {
    setVisibility(!visible);
    console.log("DDD");
  };

  const onClose = () => {
    setVisibility(!visible);
  };  
  const MenuItem = () => {
    const scrollTo = (id: string) => {
       console.log("1spn"+id+"===activeClass");
      //setSelected(id);
      const element = document.getElementById(id) as HTMLDivElement;
 console.log("2spn"+id+"===activeClass");

      if(element == null) {

      	if(id.indexOf("/").toString() == "0")
      		document.location.href =""+id;
		else if(id.toString() == "requestdemo")
      		document.location.href ="/rfq";      		
      	else 
	      	document.location.href ="/#"+id;
      	return false;
      }
       console.log("3spn"+id+"===activeClass");
      element.scrollIntoView({
        behavior: "smooth",
        
      });      
      setVisibility(false);
      
      var _ele;      
      _ele = document.getElementById("spnintro");
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnservice");
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnproduct");
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnrequestdemo");
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spnabout");
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spncontact");
      _ele.classList.remove("activeClass"); 
      _ele = document.getElementById("spn"+id);
      _ele.classList.add("activeClass"); 
       console.log("4spn"+id+"===activeClass");

     
      
    };
    
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("intro")}>
          <Span id="spnintro">{t("Home")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall >
          <Span id="spnservice" onClick={() => scrollTo("service")}>{t("Services")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span id="spnproduct">{t("Products")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("requestdemo")}>
          <Span id="spnrequestdemo">{t("Request For Demo")}</Span>
        </CustomNavLinkSmall>
        
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>

          <Span id="spnabout">{t("About Us")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span id="spncontact">
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection  id="sticky-header">   
      
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.png" width="75%" className="logomob" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
